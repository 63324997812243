<template>
	<div id="app-personal-data">
		<call-help></call-help>
		<app-stepper :step-number="1"></app-stepper>
		<div class="row flex-column justify-content-center align-items-center mainBox">
			<h2 class="col-11 col-xl-8 mb-4 text-center text-format-1">
				Please {{ nombreCliente }}, review and complete your personal information. You are very
				close to being part of {{ projectName }}
			</h2>
			<form
				class="d-flex justify-content-center flex-column align-items-center ng-untouched ng-pristine ng-invalid"
				novalidate
			>
				<div class="row d-flex justify-content-center flex-column flex-md-row w-100">
					<div class="col">
						<div class="form-group">
							<label :class="{ invalidForm: nameError }" for="name">*NAME</label>
							<input
								class="form-control"
								:class="{ invalidInfoBox: nameError }"
								id="name"
								v-model.trim="name"
								@blur="$v.name.$touch()"
								placeholder
								type="text"
								autocomplete="given-name"
							/>
							<div class="space-error">
								<span v-if="nameError" class="mt-2 invalidInfo help-block">
									{{ nameError }}
								</span>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<label :class="{ invalidForm: surname1Error }" for="surname1">
								*FIRST NAME
							</label>
							<input
								class="form-control"
								:class="{ invalidInfoBox: surname1Error }"
								id="surname1"
								v-model.trim="surname1"
								@blur="$v.surname1.$touch()"
								placeholder
								type="text"
								autocomplete="family-name"
							/>
							<div class="space-error">
								<span v-if="surname1Error" class="mt-2 invalidInfo help-block">
									{{ surname1Error }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row d-flex justify-content-center flex-column flex-md-row w-100">
					<div class="col">
						<div class="form-group">
							<label :class="{ invalidForm: surname2Error }" for="surname2">
								SECOND NAME
							</label>
							<input
								class="form-control"
								:class="{ invalidInfoBox: surname2Error }"
								id="surname2"
								v-model.trim="surname2"
								@blur="$v.surname2.$touch()"
								placeholder
								type="text"
							/>
							<div class="space-error">
								<span v-if="surname2Error" class="mt-2 invalidInfo help-block">
									{{ surname2Error }}
								</span>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<label :class="{ invalidForm: dniError }" for="numero_documento">
								*ID NUMBER
							</label>
							<input
								class="form-control"
								:class="{ invalidInfoBox: dniError }"
								id="numero_documento"
								v-model.trim="dni"
								@change="$v.dni.$touch()"
								@blur="() => (dni = dni.toUpperCase())"
								placeholder
								type="text"
								autocomplete="documentId"
							/>
							<div class="space-error">
								<span v-if="dniError" class="mt-2 invalidInfo help-block">
									{{ dniError }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center flex-column flex-md-row w-100">
					<div class="col">
						<div class="form-row">
							<div class="form-group col-3">
								<label :class="{ invalidForm: ageError }" for="dia">
									*BIRTH DATE
								</label>
								<select
									v-model.number="$v.diaNacimiento.$model"
									class="form-control"
									:class="{ invalidInfoBox: ageError }"
									autocomplete="bday-day"
								>
									<option disabled value="undefined">Day</option>

									<option v-for="day in 31" :value="day" :key="day">{{ day }}</option>
								</select>
								<div class="space-error">
									<span v-if="ageError" class="mt-2 text-nowrap invalidInfo help-block">
										{{ ageError }}
									</span>
								</div>
							</div>
							<div class="form-group col-5">
								<label :class="{ invalidForm: ageError }" class="text-white" for="mes">
									'
								</label>
								<select
									v-model.number="$v.mesNacimiento.$model"
									class="form-control"
									:class="{ invalidInfoBox: ageError }"
									autocomplete="bday-month"
								>
									<option disabled value="undefined">Month</option>

									<option value="1">Enero</option>
									<option value="2">Febrero</option>
									<option value="3">Marzo</option>
									<option value="4">Abril</option>
									<option value="5">Mayo</option>
									<option value="6">Junio</option>
									<option value="7">Julio</option>
									<option value="8">Agosto</option>
									<option value="9">Septiembre</option>
									<option value="10">Octubre</option>
									<option value="11">Noviembre</option>
									<option value="12">Diciembre</option>
								</select>
							</div>
							<div class="form-group col-4">
								<label :class="{ invalidForm: ageError }" class="text-white" for="dia">
									'
								</label>
								<select
									v-model.number="$v.anoNacimiento.$model"
									class="form-control select-style"
									:class="{ invalidInfoBox: ageError }"
									autocomplete="bday-year"
								>
									<option disabled value="undefined">Year</option>

									<option
										v-for="year in 100"
										:value="currentYear - (year - 1)"
										:key="currentYear - (year - 1)"
									>
										{{ currentYear - (year - 1) }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<label :class="{ invalidForm: placeOfBirthError }" for="poblacion_nacimiento">
								*BIRTH CITY
							</label>
							<input
								class="form-control ng-untouched ng-pristine ng-invalid"
								:class="{ invalidInfoBox: placeOfBirthError }"
								id="poblacion_nacimiento"
								v-model.trim="placeOfBirth"
								@blur="$v.placeOfBirth.$touch()"
								type="text"
							/>
							<div class="space-error">
								<span v-if="placeOfBirthError" class="mt-2 invalidInfo help-block">
									{{ placeOfBirthError }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="confirm-button-group">
					<button
						data-test-id="confirm-button"
						class="button-confirm mb-5 mt-3 mb-sm-3"
						type="submit"
						@click.prevent="submit"
					>
						NEXT
					</button>
				</div>
				<app-mobile-call></app-mobile-call>
			</form>
		</div>
	</div>
</template>

<script>
import CallHelp from '@/components/call-help';
import MExpiredId from '@modals/m-expired-id';
import MMinorNotAllowedVue from '@modals/m-minor-not-allowed';
import AppMobileCall from '@/components/app-mobile-call';
import AppStepper from '@/components/app-stepper';
import { alpha } from '@/utils/validators';
import { helpers, required, maxLength, numeric, minValue } from 'vuelidate/lib/validators';
import { mapErrorFields } from '@/utils/helpers';
import { mapState } from 'vuex';

// const formatDate = (date) => {
// 	const d = new Date(date);
// 	let month = `${d.getMonth() + 1}`;
// 	let day = `${d.getDate()}`;
// 	const year = d.getFullYear();

// 	if (month.length < 2) {
// 		month = `0${month}`;
// 	}
// 	if (day.length < 2) {
// 		day = `0${day}`;
// 	}

// 	return [year, month, day].join('-');
// };

const { VUE_APP_CURRENT_PROJECT } = process.env;
const projectName = VUE_APP_CURRENT_PROJECT === 'kukenbank' ? 'Kukenbank' : 'Excibank';

export default {
	name: 'app-person-data',

	components: {
		CallHelp,
		AppStepper,
		AppMobileCall,
	},

	data() {
		return {
			projectName,
			name: '',
			surname1: '',
			surname2: '',
			dni: '',
			diaNacimiento: null,
			mesNacimiento: null,
			anoNacimiento: null,
			placeOfBirth: '',
			currentYear: new Date().getFullYear(),
		};
	},

	computed: {
		...mapState('onboarding', ['nombreCliente']),
		...mapErrorFields([
			'name',
			'surname1',
			'surname2',
			'dni',
			'age',
			'diaNacimiento',
			'mesNacimiento',
			'anoNacimiento',
			'placeOfBirth',
		]),

		age({ diaNacimiento, mesNacimiento, anoNacimiento }) {
			const dateToCalculate = new Date();
			const dob = new Date(`${mesNacimiento}/${diaNacimiento}/${anoNacimiento}`).getTime();
			const dateToCompare = new Date(dateToCalculate).getTime();
			const age = Math.floor((dateToCompare - dob) / (365.25 * 24 * 60 * 60 * 1000));
			return age;
		},
	},

	mounted() {
		const { data } = this.$store.state.eid;

		if (data.birthdate) {
			const [year, month, day] = data.birthdate.split('-');
			this.diaNacimiento = ~~day; // eslint-disable-line no-bitwise
			this.mesNacimiento = ~~month; // eslint-disable-line no-bitwise
			this.anoNacimiento = ~~year; // eslint-disable-line no-bitwise
		}

		if (this.age < 18) {
			this.$store.dispatch('modal/open', MMinorNotAllowedVue);
		}

		this.name = data.name || '';
		this.surname1 = data.surname1 || '';
		this.surname2 = data.surname2 || '';
		this.placeOfBirth = data.placeOfBirth || '';
		this.dni = data.documentNumber || '';

		/**
		 * Los DNI permanentes son marcados con fecha de caducidad
		 * 01/01/99 y erróneamente el sistema los reconoce como 1999
		 * en vez de 9999. Pero son DNI que no caducan.
		 */
		if (data.expiredDate && data.expiredDate !== '1999-01-01') {
			if (new Date(data.expiredDate) <= new Date()) {
				this.$store.dispatch('modal/open', MExpiredId);
			}
		}

		this.$nextTick(() => {
			this.name = this.nombreCliente;
		});
	},

	validations: {
		name: {
			alpha,
			required,
			maxLength: maxLength(45),
		},
		surname1: {
			alpha,
			required,
			maxLength: maxLength(45),
		},
		surname2: {
			alpha,
			maxLength: maxLength(45),
		},
		dni: {
			required,
			validDNI: helpers.withParams({ type: 'validDNI' }, (value) => {
				const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
				const str = value.toString().toUpperCase();

				const letter = str.substr(-1);
				const charIndex = parseInt(str.substr(0, 8), 0) % 23;

				return validChars.charAt(charIndex) === letter;
			}),
		},
		diaNacimiento: {
			required,
			numeric,
		},
		mesNacimiento: {
			required,
			numeric,
		},
		anoNacimiento: {
			required,
			numeric,
		},
		placeOfBirth: {
			alpha,
			required,
			maxLength: maxLength(35),
		},
		age: {
			minValue: minValue(18),
			numeric,
		},
	},

	watch: {
		age() {
			this.$v.age.$touch();
		},
	},

	methods: {
		submit() {
			// const { $v, $router } = this;
			// const {
			// 	name,
			// 	surname1,
			// 	surname2,
			// 	dni,
			// 	diaNacimiento,
			// 	mesNacimiento,
			// 	anoNacimiento,
			// 	placeOfBirth,
			// } = this;

			// $v.$touch();
			// if (!$v.$invalid) {
			// 	this.$store
			// 		.dispatch('onboarding/saveProcess', {
			// 			name,
			// 			surname1,
			// 			surname2,
			// 			idDocument: {
			// 				type: 'DNI',
			// 				id: dni,
			// 			},
			// 			dateOfBirth: formatDate(`${mesNacimiento}/${diaNacimiento}/${anoNacimiento}`),
			// 			placeOfBirth,
			// 		})
			// 		.then(() => {
			// 			$router.push('app-address');
			// 		});
			// }
			this.$router.push('app-address');
		},
	},
};
</script>

<style lang="scss" scoped>
.mainBox {
	margin: 20px 0 0;
}
form {
	width: 80%;
}
label {
	white-space: nowrap;
}
option {
	color: #113456 !important;
	font-family: Avenir, sans-serif !important;
	background: #fff;
}
.invalidForm {
	color: red;
}
.invalidInfo {
	display: inline-block;
	color: red;
}
.invalidInfoBox {
	border: 1px solid red;
}
.space-error {
	padding: 6px 0;
	position: relative;
}
.space-error span {
	font-size: 12px;
	position: absolute;
	top: -7px;
}

form {
	width: 100%;
}

.confirm-button-group {
	width: 100%;
	padding: 0 14px;
	text-align: center;
}
</style>
