<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">AVISO</h2>
			<p class="text-format-2x text-center px-3">
				Wow, your ID seems to be out of date. We are very sorry, but we need a valid ID in order to
				continue with the process.
			</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				@click="reload"
				class="button-confirm mb-5 txt-uc"
				type="button"
			>
				ABANDON THE PROCESS
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-expired-id',

	methods: {
		reload() {
			window.document.location.reload();
		},
	},
};
</script>
