<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">NOTICE</h2>
			<p class="text-format-2x text-center px-3">
				Well, it looks like we won't be able to continue. According to your documentation you are a
				minor and cannot apply for an account through this channel. We will be happy to inform you
				about how to open your transparent account as a minor by calling 912 34 56 78.
			</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				@click="reload"
				class="button-confirm mb-5 txt-uc"
				type="button"
			>
				ABANDON THE PROCESS
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-minor-not-allowed',

	methods: {
		reload() {
			window.document.location.reload();
		},
	},
};
</script>
